<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <loading v-if="cargando" />
      <section class="content-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">
              <h5 class="mb-0">Internos</h5>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">Tep</li>
                <li class="breadcrumb-item active">General</li>
                <li class="breadcrumb-item active">Dashboard</li>
                <li class="breadcrumb-item active">Internos</li>
              </ol>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="fecha_inicial">Fecha Inicial</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="fecha_inicial"
                  v-model="filters.fecha_inicial"
                  @input="validaFechas"
                />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label for="fecha_final">Fecha Final</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="fecha_final"
                  v-model="filters.fecha_final"
                  @input="validaFechas"
                />
              </div>
            </div>
            <div class="col-sm-2">
              <button
                type="button"
                @click="getDatos(), getIndicadoresInt()"
                class="btn alert-default-info mt-4"
                v-if="!$v.filters.$invalid"
              >
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div class="card p-3">
        <div class="card-header bg-frontera">
          <h3 class="card-title text-white">
            <i class="fas fa-poll text-white"></i>
            <b> Indicadores</b>
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <div class="info-box mb-3 bg-navy">
                <span class="info-box-icon"><i class="fas fa-users"></i></span>
                <div class="info-box-content">
                  <span class="info-box-text"
                    >Total Pasajeros Que Se Movilizaron</span
                  >
                  <span class="info-box-number">{{
                    indicadores.pasajeros
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="info-box mb-3 bg-success">
                <span class="info-box-icon"
                  ><i class="fas fa-clipboard-check"></i
                ></span>
                <div class="info-box-content">
                  <span class="info-box-text"
                    >Cantidad De Servicios Ejecutados</span
                  >
                  <span class="info-box-number">{{
                    indicadores.ejecutados
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="info-box mb-3 bg-danger">
                <span class="info-box-icon"
                  ><i class="fas fa-user-friends"></i
                ></span>
                <div class="info-box-content">
                  <span class="info-box-text">Total Conductores</span>
                  <span class="info-box-number">{{
                    indicadores.conductores
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="info-box mb-3 bg-info">
                <span class="info-box-icon"
                  ><i class="fas fa-concierge-bell"></i
                ></span>
                <div class="info-box-content">
                  <span class="info-box-text"
                    >Cantidad De Servicios Solicitados</span
                  >
                  <span class="info-box-number">{{
                    indicadores.solicitados
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div class="card card-chart">
              <div class="card-header bg-frontera">
                <h3 class="card-title text-white">
                  <i class="fas fa-tachometer-alt"></i>
                  <b> Kilometros Recorridos</b>
                </h3>
              </div>
              <div class="card-body">
                <TepGraficaKmRecorridos ref="TepGraficaKmRecorridos" />
              </div>
              <div class="card-footer"></div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card card-chart">
              <div class="card-header bg-frontera">
                <h3 class="card-title text-white">
                  <i class="fas fa-truck"></i>
                  <b> Total Vehículos</b>
                </h3>
              </div>
              <div class="card-body">
                <TepGraficaTotalVh ref="TepGraficaTotalVh" />
              </div>
              <div class="card-footer"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="card card-chart">
              <div class="card-header bg-frontera">
                <h3 class="card-title text-white">
                  <i class="fa fa-globe-americas text-white"></i>
                  <b> Vueltas al mundo</b>
                </h3>
              </div>
              <div class="card-body">
                <TepGraficaVueltasMundoInternos
                  ref="TepGraficaVueltasMundoInternos"
                />
              </div>
              <div class="card-footer"></div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card card-chart">
              <div class="card-header bg-frontera">
                <h3 class="card-title text-white">
                  <i class="fas fa-tachometer-alt"></i>
                  <b> Kilometros Extra</b>
                </h3>
              </div>
              <div class="card-body">
                <TepGraficaKmExtraInternos ref="TepGraficaKmExtraInternos" />
              </div>
              <div class="card-footer"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-chart">
              <div class="card-header bg-frontera">
                <h3 class="card-title text-white">
                  <i class="far fa-calendar-alt"></i>
                  <b> Turnos Finalizados</b>
                </h3>
              </div>
              <div class="card-body">
                <TepGraficaTurnosFinalizadosInternos
                  ref="TepGraficaTurnosFinalizadosInternos"
                />
              </div>
              <div class="card-footer"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-chart">
              <div class="card-header bg-frontera">
                <h3 class="card-title text-white">
                  <i class="far fa-calendar-alt"></i>
                  <b> Turnos Operacion / Área</b>
                </h3>
              </div>
              <div class="card-body">
                <TepGraficaTurnosOpAreaInternos
                  ref="TepGraficaTurnosOpAreaInternos"
                />
              </div>
              <div class="card-footer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import TepGraficaVueltasMundoInternos from "../internos/TepGraficaVueltasMundoInternos";
import TepGraficaKmExtraInternos from "../internos/TepGraficaKmExtraInternos";
import TepGraficaTurnosFinalizadosInternos from "../internos/TepGraficaTurnosFinalizadosInternos";
import TepGraficaTurnosOpAreaInternos from "../internos/TepGraficaTurnosOpAreaInternos";
import TepGraficaTotalVh from "../internos/TepGraficaTotalVh";
import TepGraficaKmRecorridos from "../internos/TepGraficaKmRecorridos";
import axios from "axios";
export default {
  name: "DashboardTepInternos",
  components: {
    TepGraficaVueltasMundoInternos,
    TepGraficaKmExtraInternos,
    TepGraficaTurnosFinalizadosInternos,
    TepGraficaTurnosOpAreaInternos,
    TepGraficaKmRecorridos,
    TepGraficaTotalVh,
    Loading,
  },
  data() {
    return {
      cargando: true,
      filters: {
        fecha_inicial: "",
        fecha_final: "",
      },
      indicadores: [],
      statusInterno: [],
      search: "",
      hoy: moment().format("YYYY-MM-DD"),
    };
  },

  validations: {
    filters: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
    },
  },

  methods: {
    async getIndex() {
      this.cargando = true;
      this.filters.fecha_inicial = moment(this.hoy)
        .subtract(1, "M")
        .format("YYYY-MM-DD");
      this.filters.fecha_final = this.hoy;
      await this.$refs.TepGraficaVueltasMundoInternos.getGrafica();
      await this.$refs.TepGraficaKmExtraInternos.getGrafica();
      await this.$refs.TepGraficaTurnosFinalizadosInternos.getGrafica();
      await this.$refs.TepGraficaTurnosOpAreaInternos.getGrafica();
      await this.$refs.TepGraficaKmRecorridos.getGrafica();
      await this.$refs.TepGraficaTotalVh.getGrafica();
      await this.getIndicadoresInt();
      this.cargando = false;
    },

    async getDatos() {
      this.cargando = true;
      await this.$refs.TepGraficaVueltasMundoInternos.getGrafica();
      await this.$refs.TepGraficaKmExtraInternos.getGrafica();
      await this.$refs.TepGraficaTurnosFinalizadosInternos.getGrafica();
      await this.$refs.TepGraficaTurnosOpAreaInternos.getGrafica();
      await this.$refs.TepGraficaKmRecorridos.getGrafica();
      await this.$refs.TepGraficaTotalVh.getGrafica();
      this.cargando = false;
    },

    async getIndicadoresInt() {
      let me = this;
      this.cargando = true;
      await axios
        .get("/api/tep/dashboardTep/getGraficaIndicadoresInt", {
          params: this.filters,
        })
        .then(async (response) => {
          me.indicadores = response.data;
          me.cargando = false;
        });
    },

    async getInternos() {
      let me = this;
      this.cargando = true;
      await axios
        .get("/api/tep/dashboardTep/graficaStatusInterno", {
          params: this.filters,
        })
        .then(async (response) => {
          me.statusInterno = response.data;
        });
    },

    validaFechas() {
      if (this.filters.fecha_inicial && this.filters.fecha_final) {
        const fecha_menor = new Date(this.filters.fecha_inicial);
        const fecha_mayor = new Date(this.filters.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filters.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
  },

  async mounted() {
    await this.getIndex();
  },
};
</script>
