<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";

export default {
  name: "TepGraficaTurnosOpArea",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        chart: {
          type: "column",
        },

        colors: [
          "#ff1c79",
          "#001871",
          "#ba4477",
          "#4b3074",
          "#c84778",
          "#DB843D",
          "#828282",
          "#444444",
          "#000000",
        ],

        title: {
          text: "Turnos Operacion / Area",
        },

        xAxis: {
          categories: [],
          title: {
            text: "# Sitios",
          },
          crosshair: true,
        },

        yAxis: {
          title: {
            text: "# Turnos",
          },
          crosshair: true,
        },

        series: [
          {
            data: [],
          },
        ],

        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },

        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },

        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/tep/dashboardTepInternos/turnosOpArea", {
          params: this.$parent.filters,
        })
        .then(async (response) => {
          me.respuesta = response.data;
          await me.graficaTurnosOpArea(me.respuesta);
        });
    },

    async graficaTurnosOpArea(resp) {
      this.chartOptions.xAxis.categories = resp.categorias;
      this.chartOptions.series = resp.series;
      this.chartOptions.series.marker = {
        lineWidth: 15,
        fillColor: "black",
      };
    },
  },
};
</script>

<style>
.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

#container {
  height: 400px;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}
</style>
