<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";

export default {
  name: "TepGraficaTotalVh",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        chart: {
          backGroundColor: "#ff1c79",
          type: "bar",
        },

        xAxis: {
          categories: [],
          title: {
            text: "Cantidad de Vehículos",
          },
        },

        yAxis: {
          title: {
            text: "",
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "CANTIDAD DE VEHICULOS",
          align: "center",
        },

        series: [
          {
            data: [{}],
            marker: {
              lineWidth: 3,
              fillColor: "white",
            },
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "#ff1c79"],
                [1, "#001871"],
              ],
            },
          },
        ],

        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/tep/dashboardTepInternos/totalVh", {
          params: this.$parent.filters,
        })
        .then(async (response) => {
          me.respuesta = response.data;
          await me.graficaTotalVehiculos(me.respuesta);
        });
    },

    async graficaTotalVehiculos(resp) {
      let cantVehi = [];
      let tipoVh = [];

      this.chartOptions.series[0].data = [{}];

      for (let i = 0; i < resp.length; i++) {
        if (resp[i].nTipoVh != 0 && resp[i].nTipoVh != null) {
           tipoVh.push(resp[i].nTipoVh);
           cantVehi.push(parseFloat(resp[i].cantVehi));
        }
      }

      this.chartOptions.xAxis.categories = tipoVh;

      for (let i = 0; i < tipoVh.length; i++) {
        let objGraf = {
          y: cantVehi[i],
        };

        this.chartOptions.series[0].data[i] = objGraf;
        this.chartOptions.series[0].name = "N° Vehiculos Por Tipologia";
      }
    },
  },
};
</script>
