<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";

export default {
  name: "TepGraficaKilometrosRecorridos",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        chart: {
          type: "waterfall",
        },
        xAxis: {
          categories: [],
          title: {
            text: "Meses",
          },
        },

        yAxis: {
          title: {
            text: "Cantidad Kilometros Recorridos",
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "KILOMETROS RECORRIDOS",
          align: "center",
        },

        series: [
          {
            data: [{}],
            marker: {
              lineWidth: 3,
              fillColor: "white",
            },
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "#ff1c79"],
                [1, "#001871"],
              ],
            },
          },
        ],

        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/tep/dashboardTepInternos/kmRecorridos", {
          params: this.$parent.filters,
        })
        .then(async (response) => {
          me.respuesta = response.data;
          await me.graficaKilometrosRecorridos(me.respuesta);
        });
    },

    async graficaKilometrosRecorridos(resp) {
      let meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      let kmRecorridos = [];      
      let mes = [];

      this.chartOptions.series[0].data = [{}];

      for (let i = 0; i < resp.length; i++) {
        if (resp[i].mes != 0 && resp[i].mes != null) {
          mes.push(meses[resp[i].mes - 1]);
          kmRecorridos.push(parseFloat(resp[i].sumaKm));
        }
      }

      this.chartOptions.xAxis.categories = mes;

      for (let i = 0; i < mes.length; i++) {
        let objGraf = {
          y: kmRecorridos[i],
        };

        this.chartOptions.series[0].data[i] = objGraf;
        this.chartOptions.series[0].name = "N° Kilometros Recorridos";
      }
    },
  },
};
</script>
